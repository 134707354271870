import React from 'react'

import {
  Heading,
  List,
  ListItem,
  Paragraph,
  Typography
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

import { ListItemHTMLExample } from '../listat-ja-taulukot/list'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Typography"
    components={[{ component: Typography }]}
  >
    <Section>
      <Paragraph>
        Kappaleen tai listan jälkeiselle otsikolle tulee tavallista isompi
        marginaali.
      </Paragraph>
    </Section>
    <Section title="Esimerkki">
      <Playground>
        <Typography>
          <Heading level={3}>Mauris sed libero</Heading>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien. Mauris varius diam vitae arcu.
          </Paragraph>
          <Paragraph>
            Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit.
            Sed augue orci, lacinia eu tincidunt et eleifend nec lacus. Donec
            ultricies nisl ut felis, suspendisse potenti.
          </Paragraph>
          <Heading level={3}>Suspendisse facilisis</Heading>
          <List>
            <ListItem>Lorem ipsum dolor sit amet</ListItem>
            <ListItem>Consectetuer adipiscing elit</ListItem>
            <ListItem>Sed posuere interdum sem</ListItem>
          </List>
          <Heading level={3}>Nulla in lacinia laoreet</Heading>
          <Paragraph noMargin>
            Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae,
            consectetuer et venenatis eget velit. Sed augue orci, lacinia eu
            tincidunt et eleifend nec lacus. Donec ultricies nisl ut felis,
            suspendisse potenti.
          </Paragraph>
        </Typography>
      </Playground>
      <Playground format="html">
        <div className="typography">
          <h3>Mauris sed libero</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien. Mauris varius diam vitae arcu.
          </p>
          <p>
            Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit.
            Sed augue orci, lacinia eu tincidunt et eleifend nec lacus. Donec
            ultricies nisl ut felis, suspendisse potenti.
          </p>
          <h3>Suspendisse facilisis</h3>
          <ul className="list">
            <ListItemHTMLExample>
              Lorem ipsum dolor sit amet
            </ListItemHTMLExample>
            <ListItemHTMLExample>
              Consectetuer adipiscing elit
            </ListItemHTMLExample>
            <ListItemHTMLExample>Sed posuere interdum sem</ListItemHTMLExample>
          </ul>
          <h3>Nulla in lacinia laoreet</h3>
          <p className="mb-0">
            Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae,
            consectetuer et venenatis eget velit. Sed augue orci, lacinia eu
            tincidunt et eleifend nec lacus. Donec ultricies nisl ut felis,
            suspendisse potenti.
          </p>
        </div>
      </Playground>
    </Section>
  </Content>
)

export default Page
